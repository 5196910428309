import { createGlobalState } from 'react-hooks-global-state';

type GlobalStateType = {
  showDuplicateModal: boolean,
  cadTool?: string,
  checksComplete?: boolean,
};

const { useGlobalState } = createGlobalState<GlobalStateType>({
  showDuplicateModal: false,
  cadTool: 'Not set',
  checksComplete: false,
});

export default useGlobalState;
